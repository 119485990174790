.count-prod {
    font-size: 1.14rem;
    line-height: 0.79rem;
}
.cu-biblestudylogo {
    color: #00b9f7;
    margin-right: 15px;
    font-size: 3.93rem;
    line-height: 2.57rem;
}
.navbar-inner-pages .cu-biblestudylogo {
    font-size: 46px;
    line-height: 35px;
}
ul.nav.navbar-nav {
    margin-top: 2px;
}
.navbar-inner-pages .navbar-nav i {
    font-size: 1.07rem;
}
.navbar-nav i {
    font-size: 1.43rem;
    color: #555555;
    margin-right: 5px;
    vertical-align: text-top;
}
.home .navbar-nav i {
    color: #ffffff;
}
ul.nav.navbar-nav li  a {
    text-transform: uppercase;
    font-family: ns_widedemibold;
    color: #6b6b6b;
    font-size: 1.07rem;
    padding: 11px 18px;
}
.home ul.nav.navbar-nav li  a {
    color: #ffffff;
}
.home a.cu-drop-menu-item {
    color: #ffffff;
}
.dropdown-menu li a {
    color: #6b6b6b !important;
}
.navbar-inner-pages ul.nav.navbar-nav li  a {
    font-size: 0.93rem;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a.active,
.navbar-default .navbar-nav > li.open > a,
.navbar-default .navbar-nav > li.open > a:focus,
.navbar-default .navbar-nav > li.open > a:hover,
.navbar-default .navbar-right > li > a:hover,
.navbar-inner-pages.navbar-default .navbar-nav > a:hover,
.navbar-inner-pages.navbar-default .navbar-nav > .open > a,
.navbar-inner-pages.navbar-default .navbar-nav > .open > a:focus,
.navbar-inner-pages.navbar-default .navbar-nav > .open > a:hover,
.navbar-inner-pages.navbar-default .navbar-right > li > a:hover{
    color: white !important;
    background-color: #00b9f7 !important;
    border-radius: 25px;
    -webkit-border-radius: 25px;
}
.navbar-default .navbar-nav > li > a.active,
.navbar-default .navbar-right > li > a.active,
.navbar-inner-pages.navbar-default .navbar-right > li > a.active
{
    background-color: #313d47 !important;
}
ul.dropdown-menu{
    padding: 10px 0 !important;
}
ul.dropdown-menu li{
    margin: 0px !important;
}
ul.dropdown-menu li a{
    padding: 11px 30px !important;
}
ul.dropdown-menu li i{
    color: #00b9f7 !important;
    width: 1.29rem;
    display: inline-block;
}

.navbar-default .navbar-nav > li > a:hover > i,
.navbar-default .navbar-nav > li > a.active > i{
    color: white;
}
.navbar-inner-pages.navbar-default .navbar-nav > a:hover,
.navbar-inner-pages.navbar-default .navbar-nav > .open > a,
.navbar-inner-pages.navbar-default .navbar-nav > .open > a:focus,
.navbar-inner-pages.navbar-default .navbar-nav > .open > a:hover {
    background-color: #313d47;
}
.navbar-default .navbar-nav > .open > a i,
.navbar-default .navbar-nav > .open > a:focus i,
.navbar-default .navbar-nav > .open > a:hover i {
    color: white;
}
ul.nav.navbar-nav li.bord-menu-item  a{
    border: 2px solid #00b9f7 !important;
    color: #00b9f7 !important;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    padding: 9px 16px;
}
ul.nav.navbar-nav li.bord-menu-item  a:hover{
    color: white !important;
}
ul.nav.navbar-nav li.bord-menu-item  a.active{
    color: white !important;
    border: 2px solid #313d47  !important;
    background-color: #313d47 !important;
}
.navbar-inner-pages ul.nav.navbar-nav li.bord-menu-item  a{
    border: 2px solid #00b9f7 !important;
    color: #00b9f7 !important;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    padding: 9px 16px;
}
.navbar-inner-pages ul.nav.navbar-nav li.bord-menu-item  a:hover{
    color: white !important;
}
.navbar-inner-pages ul.nav.navbar-nav li.bord-menu-item  a.active{
    color: white !important;
    border: 2px solid #313d47  !important;
    background-color: #313d47 !important;
}
ul.nav.navbar-nav li {
    margin: 0 8px;
}
.navbar-default {
    background: transparent;
    border: none;
    padding: 40px 0 30px;
}
.logo-text {
    display: inline-block;
    height: 3.93rem;
    vertical-align: top;
}

/* ------------ Search header input ----------- */
.c-search1 {
    width: 220px;
    margin: 8px 15px 0;
    position: relative;
}
.search-text1 {
    border: none;
    border-bottom: 1px solid #878787;
    border-radius: 0;
    -webkit-border-radius: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
    padding: 0 0 0 30px;
    color: #bfbfbf;
    font-size: 1rem;
    font-family: "work_sanslight";
}
.search-btn1 {
    border: none;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 2.43rem;
}
.search-btn1 i {
    color: #acacac;
}

/* -------------------- Login Dropdown Menu ------------------ */
.dropdown-menu{
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -webkit-box-shadow: 0 0 15px 5px rgba(100,100,100,0.1);
    box-shadow: 0 0 15px 5px rgba(100,100,100,0.1);
    padding: 10px 5px;
    min-width: 200px;
    left: -31px;
    top: 56px;
}
.navbar-default.navbar-inner-pages .log-pop .dropdown-menu{
    left: -89px;
}
.navbar-default .dropdown-menu{
    right: 0;
    left: auto;
}
.navbar-default.navbar-inner-pages .dropdown-menu{
    right: 0;
    left: auto;
}
.navbar-default .log-pop .dropdown-menu{
    left: -82px;
}
.log-pop.dropdown .dropdown-menu li a {
    font-size: 0.93rem;
    font-family: ns_widemedium;
    color: #000000;
}
.dropdown-menu li a:hover,
.dropdown-menu li a:hover i{
    background: #00b9f7 !important;
    color: white !important;
}
.dropdown-menu .popup-arrow {
    right: 14px;
    top: -7px;
    transform: rotate(36deg) skew(-20deg);
    width: 12px;
    height: 12px;
}
.user-default {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    display: inline-block;
    background: url('/images/default.jpg') center no-repeat;
    background-size: cover;
    vertical-align: middle;
    margin-right: 5px;
}
.user-default2 {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    display: inline-block;
    background: url('/images/default.jpg') center no-repeat;
    background-size: cover !important;
    vertical-align: middle;
    margin: 0 2px !important;
}
.like-item {
    display: inline-block;
}
.cu-drop-menu-item {
    padding-top: 9px !important;
}
ul.nav.navbar-nav li a.cu-drop-menu-item:hover,
ul.nav.navbar-nav li a.cu-drop-menu-item:focus,
ul.nav.navbar-nav li a.cu-drop-menu-item:active,
ul.nav.navbar-nav li a.cu-drop-menu-item:visited,

.navbar-inner-pages.navbar-default .navbar-nav > a.cu-drop-menu-item:hover,
.navbar-inner-pages.navbar-default .navbar-nav > .open > a.cu-drop-menu-item,
.navbar-inner-pages.navbar-default .navbar-nav > .open > a.cu-drop-menu-item:focus,
.navbar-inner-pages.navbar-default .navbar-nav > .open > a.cu-drop-menu-item:hover,

.navbar-default .navbar-nav > a.cu-drop-menu-item:hover,
.navbar-default .navbar-nav > .open > a.cu-drop-menu-item,
.navbar-default .navbar-nav > .open > a.cu-drop-menu-item:focus,
.navbar-default .navbar-nav > .open > a.cu-drop-menu-item:hover
{
    background-color: transparent !important;
    color: #6b6b6b !important;
}
.checkbox-disabled {
    opacity: 0.5;
}
.user-neme-cut {
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    max-width: 80px;
    text-overflow: ellipsis;
    height: 23px;
    vertical-align: middle;
}
.sub-nav li i {
    color: #00b9f7;
    margin-right: 8px;
}
.sub-nav li a:hover i {
    color: #ffffff;
    margin-right: 8px;
}
@media(--xsmall){
    .sub-nav li i {
        color: #9da8b1;
        margin-right: 8px;
    }
    .sub-nav li a:hover i {
        color: #ffffff;
        margin-right: 8px;
    }
}

iframe{
    max-width: 100% !important;
}

a.my-notifications{
    padding: 11px 11px !important;
}

.bg-home a.my-notifications {
    /*padding: 11px 11px;*/
}

a.my-notifications .notif-menu-text{
    display: none;
}
@media (--xsmall) {
    a.my-notifications .notif-menu-text{
        display: block;
    }
    a.my-notifications .counter{
        right: 0px;
        top: 11px;
    }
}
.my-notifications-li{
    margin: 0 !important;
}
@media (--xsmall) {
    .my-notifications-li{
        margin: 0 8px !important;
    }
}
a.my-notifications:hover{
    /*background: none !important;
    color: #00b9f7 !important;*/
}
.my-notifications i{
    font-size: 1.45em !important;
}
@media (--xsmall) {
    .my-notifications i{
        margin-right: 8px;
        font-size: 1.43em;
    }
}
/*.sub-nav:before{
    content: '';
    border-top: 1px solid #81878b;
    border-left: 1px solid #81878b;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    right: 14px;
    top: -7px;
    width: 12px;
    height: 12px;
    background-color: white;
    position: absolute;
}*/

.notifications-popup{
    width: 400px;
    padding-bottom: 0px !important;
    text-align: left;
    /*max-height: 450px;
    overflow: auto;*/
}

.notifications-popup .notification-item{
    padding: 10px 30px 10px 80px !important;
    min-height: 70px;
    position: relative;
}

.notifications-popup .notification-item .user-image{
    top: 5px;
    left: 10px;
}

nav ul li .sub-nav.notifications-popup:before{
    right: 15px;
}

.notifications-popup .notification-item{
    font-family: work_sansregular;
    font-size: 1rem;
}

.notifications-popup .all-notifications-block a{
    text-align: center;
    display: block;
    text-transform: none;
    font-family: work_sansregular !important;
    font-size: 1.2rem !important;
}

.notifications-popup .notification-item a{
    padding: 0 !important;
    text-decoration: underline;
    text-transform: none;
    font-size: 1rem !important;
    display: inline;
    font-family: work_sansregular !important;
    white-space: normal;
}

.all-notifications-block{
    position: relative;
}

.notifications-popup .notification-item a:hover,
.all-notifications-block a:hover{
    background: none !important;
    color: #00b9f7 !important;
}

.notifications-popup .notification-item .right-block{
    position: absolute;
    right: 0px;
    top: 10px;
}

.all-notifications-block .right-block{
    position: absolute;
    right: 10px;
    top: 12px;
}

.notifications-popup .notification-item a.mark-read{
    text-decoration: none;
    font-size: 1.2rem !important;
}

.notifications-popup .notification-item a.mark-read i{
    color: #49ba93 !important;
}

.all-notifications-block a.mark-all-read{
    /*padding:0 !important;*/
}

.all-notifications-block a.mark-all-read i{
    margin: 0 !important;
}

.notifications-popup .notification-item a.mark-read:hover i,
.all-notifications-block a.mark-all-read:hover i{
    color: #49ba93 !important;
}

.my-notifications .counter{
    font-family: ns_widedemibold;
    position: absolute;
    right: 6px;
    top: 4px;
    background: #6b6b6b;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    font-size: 1rem !important;
    font-style: normal;
    color: #fff;
    line-height: 1.1rem;
    text-align: center;
}

.bg-home .my-notifications .counter{
    background: #fff;
    color: #6b6b6b;
}

@media (--xsmall) {
    .my-notifications .counter{
        background: #fff;
        color: #313D47;
    }

    .my-notifications:hover i.counter{
        color: #313D47 !important;
    }

    .show-menu{
        position: relative !important;
    }
    .show-menu .counter{
        position: absolute;
        right: 2px;
        top: -8px;
        background: #00b9f7;
        border: 3px solid white;
        border-radius: 50%;
        width: 23px;
        height: 23px;
        font-size: 1rem !important;
        text-align: center;
        font-style: normal;
        color: #fff;
        line-height: 1.2rem;
    }
}

.my-notifications.opened i.counter,
.my-notifications:hover i.counter{
    background: white;
    color: #00b9f7;
}

@media (--xsmall) {
    .notifications-heading{
        font-size: 1.6rem !important;
        margin: 10px 0px 48px;
    }
}

.j-notification-full-li{
    min-height: 111px;
}

.j-notification-full-li .journey-title{
    margin-right: 165px;
}

@media (--xsmall) {
    .j-notification-full-li{
        min-height: 130px;
    }
    .j-notification-full-li.is_read{
        min-height: 111px;
    }
    .j-notification-full-li .journey-title{
        margin-right: 0px;
    }
}

.j-notification-full-li .right-block{
    position: absolute;
    right: 30px;
    top: 25px;
}

@media (--xsmall) {
    .j-notification-full-li .right-block{
        left: 19px;
        top: 80px;
    }
    .j-notification-full-li .right-block .mark-read-button-text{
        display: none;
    }
}

.j-notification-full-li .right-block .mark-read{
     padding: 5px 10px;
     font-size: 1.07em;
     border-radius: 25px;
     color: white;
     background-color: #49ba93;
 }

.j-notification-full-li .right-block .mark-read:hover{
    text-decoration: none;
}

.j-notification-full-li .right-block .mark-read i{
    vertical-align: middle;
    color: white;
}

.my-notifications-full-list .mark-all-read{
    padding: 5px 10px;
    font-size: 1.07em;
    border-radius: 25px;
    color: white;
    background-color: #49ba93;
    line-height: 1.07rem;
    position: absolute;
    top: -42px;
    right: 24px;
}

@media (--xsmall) {
    .my-notifications-full-list .mark-all-read{
        right: 50%;
        margin-right: -80px;
    }
}

.my-notifications-full-list .mark-all-read:hover{
    text-decoration: none;
}

.my-notifications-full-list .mark-all-read i{
    vertical-align: middle;
    color: white;
}

.j-notification-full-li .new-sign{
    position: absolute;
    left: 75px;
    top: 25px;
    z-index: 1;
    background: #fea841;
    border-radius: 50%;
    padding: 5px;
    font-style: normal;
    color: white;
    font-size: 1rem;
    line-height: 2rem;
    width: 22px;
    height: 22px;
    border: 3px solid #fff;
}

@media (--xsmall) {
    .j-notification-full-li .new-sign{
        left: 47px;
        top: 20px;
    }
}

.j-notification-full-li .quote-text{
    position: relative;
    padding: 5px 15px;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    background-color: #f6f6f6;
    font-size: 1.14rem;
    font-family: work_sansregular;
    margin-right: 165px;
}

@media (--xsmall) {
    .j-notification-full-li .quote-text{
        margin-right: 0px;
    }
    .j-notification-full-li{
        padding: 20px 20px 30px 70px !important;
    }
}

.bg-home .log-pop{
    margin-right: 0 !important;
}

.home .bl-header nav ul li.with-items{
    margin: 0 3px;
}
