.home {
    & .btn2, & .btn2:visited {
        max-width: 410px;
        text-align: center !important;
    }
    & h2.h2-1 {
        margin-top: 0;
    }
  & .c-search1 {
    margin: 20px auto 0;
    width: 320px;
  }
  & .search-text1 {
      background: rgba(255,255,255,0.2);
  }
  & .bg-home {
      background-position: center top;
      background-repeat: no-repeat;
      background-size: auto 20%;
  }
  @media (--xsmall) {
    & .c-search1 {
      width: 80%;
      max-width: 320px;
    }
  }
  & .bg-home {
    max-width: 100%;
  }
  & .bg-g1 {
    min-height: 650px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.4+0,0+100 */
    background: -moz-linear-gradient(top,  rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0.4) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,0.4) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
  }
  @media (--xsmall) {
    & .bg-g1 {
      margin: 5px 5px 0 5px;
      min-height: 500px;
    }
  }
  & h1.h1-1 {
    color: white;
  }
  & .user-menu-name {
    color: #ffffff;
  }
  & .caret {
    color: white;
  }
  & h2.h2-3 {
    color: white;
  }
  & .home-ill-section {
    display: block;
    width: 100%;
    background-size: cover !important;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    overflow: hidden;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;
    position: relative;
    min-height: 250px;
  }


    /* --------- backgrounds for sections -------- */
    & .h-ill1 {
    padding-top: 39.2%;
    }
    & .h-ill2 {
    padding-top: 39.2%;
    }
    & .h-ill3 {
    padding-top: 39.2%;
    }
    & .h-ill4 {
    padding-top: 39.2%;
    }

    & .h-ill1 {
        background-position-x: center;
        background-position-y: top;
        background-repeat: no-repeat;
    }
    & .h-ill2 {
        background-position-x: right;
        background-position-y: center;
        background-repeat: no-repeat;
    }
    & .h-ill3 {
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
    }
    & .h-ill4 {
        background-position-x: right;
        background-position-y: center;
        background-repeat: no-repeat;
    }
    & .h-ill5 {
      background-position-x: center;
      background-position-y: center;
      background-repeat: no-repeat;
    }
    @media(--xsmall){
    & .h-ill1,
    & .h-ill2,
    & .h-ill3,
    & .h-ill4,
    & .h-ill5 {
        background-position-x: left;
        background-position-y: center;
        background-repeat: no-repeat;
    }
    }


/*  & .ill-text-pos1 {
    position: absolute;
    top: 17%;
    right: 10%;
  }*/
    & .ill-text-pos1 {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    & .ill-text-pos2 {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        right: 13%;
    }
    & .ill-text-pos3 {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        right: 32%;
    }
    & .ill-text-pos4 {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
  & .ill-text-pos5 {
      color: #ffffff;
      padding-top: 100px;

      & .p-2 {
          color: #ffffff;
          text-align: center;

          & a {
              color: #ffffff;
          }
      }
      & .c-share-panel {
          border-color: #ffffff;
          text-align: center;
      }
      & .h4-1 {
          color: white;
      }
  }


  @media (--xsmall) {
    & .ill-text-pos1,
    & .ill-text-pos2,
    & .ill-text-pos3,
    & .ill-text-pos4 {
      right: 10%;
      left: 55%;
      top: 0;
    }

    & .ill-text-pos5 {
        position: relative;
        padding-top: 50px;
        & .p-2 {
            font-size: 11px;
            line-height: 13px;
        }
    }

    & .btn2, & .btn2:hover,
    & .btn2:visited, & .btn2:focus {
        padding: 10px 15px;
        border-width: 2px;
        text-align: center;
    }

    & .btn3, & .btn3:visited,
    & .btn3:hover, & .btn3:focus {
        padding: 10px 15px;
        border-width: 2px;
        text-align: center;
    }

  }

    @media (--vxsmall2) {
        & .ill-text-pos1,
        & .ill-text-pos2,
        & .ill-text-pos3,
        & .ill-text-pos4 {
            right: 10%;
            left: 35%;
            top: 0;
        }
    }

  /* ----------- SEND A GIFT OF LOVE TO A FRIEND  ---------- */
  & .cu-verseoftheday {
    color: #00b9f7;
    padding-right: 20px;
    vertical-align: bottom;
    height: 20px;
    line-height: 2.92rem;
  }
  & .c-center-content-shop {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 10px;
    -webkit-border-radius: 10px;
  }
  & .h-ill5 {
    background: url('/images/p5-home.jpg') left center no-repeat;
    min-height: 460px;
    background-size: cover;
  }


  @media (--xsmall) {
    & .h-ill5 {
        background-size: cover;
        width: 100%;
        min-height: 100%;
    }
    & .c-center-content-shop .mt10 {
      margin-top: 0 !important;
    }
    & h2.gift-title {
      display: inline-block;
      width: 110px;
      text-align: left;
    }
    & h2.gift-title span {
      display: inline-block;
    }
    & .cu-gift {
      font-size: 45px;
      display: inline-block;
      vertical-align: super;
      margin-right: 10px;
    }
    & .gift1 {
      background-position: 50% 0 !important;
      background-size: cover !important;
      margin: 10px;
      padding-top: 70%;
    }
    & .gift-item {
      margin: 25px 2px 25px;
      overflow: hidden;
    }
    & .c-center-content-shop {
      overflow: initial;
    }
  }


  & .col-left1 {
    width: 449px;
    float: left;
  }
  & .col-right1 {
    float: left;
    max-width: 680px;
    padding: 65px 55px 40px;
  }

  @media (--medium) {
    & .col-left1,
    & .col-right1 {
      display: inline-block;
      float: none;
    }
      & .c-center-content2 {
          min-height: 10px;
          margin: 10px 0 0;
          padding: 0;
      }
  }
  & .luke {
    margin-top: 400px;
    color: #cdcdcd;
    text-align: center;
  }
  & .gift1 {
    margin: 10px;
    padding-top: 70%;
  }
  & h4.h4-gift {
    text-transform: uppercase;
    color: #464646;
    font-size: 1.64rem;
    font-family: ns_widemedium;
    padding-bottom: 5%;
  }
  @media (--medium) {
    & h4.h4-gift {
      text-transform: uppercase;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #464646;
      font-size: responsive 11px 23px;
      line-height: responsive 13px 25px;
      font-family: ns_widemedium;
      white-space: nowrap;
      padding: 0 5px;
    }
  }
  & .c-home-products .product-item {
    width: 30%;
    display: inline-block;
    padding: 0;
  }
  & .c-home-products {
    text-align: center;
    overflow: hidden;
  }
  & .gift-item {
    background: #ffffff;
    display: block;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -webkit-box-shadow: 0 0 8px 5px rgba(0,0,0,0.1);
    box-shadow: 0 0 8px 5px rgba(0,0,0,0.1);
    margin: 8px 10px 25px;
    outline: none !important;
    overflow: hidden;
  }
  @media (--xsmall) {
    & .gift-item {
      margin: 8px 3px 25px;
    }
  }
  @media (--medium) {
    & .c-share-panel {
      border: none;
      margin-left: -10px;
      margin-right: -10px;
      padding: 0!important;
    }
    & .c-share-panel h4.h4-1 {
      font-size: responsive 13px 23px;
    }
    & .at-share-btn-elements .at-icon-wrapper {
      background: transparent !important;
      color: #00b9f7 !important;
      height: 50px !important;
      width: 50px !important;
    }
    & .at-icon-wrapper.at-share-btn {
      border: 2px solid #ffffff !important;
      border-radius: 50% !important;
      -webkit-border-radius: 50% !important;
      -moz-border-radius: 50% !important;
    }
    & .at-share-btn-elements .at-icon {
      fill: #ffffff !important;
      width: 39px !important;
      height: 48px !important;
    }
    & .at-share-tbx-element .at-share-btn {
      margin-right: 20px !important;
    }
    & .at-share-tbx-element .at-share-btn:last-child {
      margin-right: 0px !important;
    }
    & h2.h2-2 {
      font-size: responsive 17px 38px;
    }
    & .c-center-content2 {
      text-align: center;
    }
  }

  @media (--xsmall) {
    & .c-center-content3.mt11 {
      margin-top: 0 !important;
    }
    & .col-right1 {
      padding: 10px 10px 0;
    }
    & .col-right1 .p-2 {
      text-align: left;
      line-height: responsive 16px 20px;
      font-size: responsive 13px 15px;
    }
    & .mt7 {
      margin-top: 25px !important;
    }
    & .pad2 {
      padding: 40px 10px;
    }
    & .mt13 {
      margin-top: 10px !important;
    }
    & .mb1 {
      margin-bottom: 0;
    }
    & .mt12 {
      margin-top: 50px !important;
    }
    & .c-center-content2 {
      border-radius: 0;
      -webkit-border-radius: 0;
      width: auto;
    }
    & .mt1 {
      margin-top: 50px !important;
    }
    & h2.h2-3 {
      font-size: 12px;
      line-height: 14px;
    }
    & h2.h2-1 {
      font-size: 16px;
      line-height: 18px;
    }
    & p.p-1 {
      font-size: 11px;
      line-height: 13px;
    }
    & .mr1 {
      margin-right: 0;
    }
    & .btn1,
    & .btn1:visited {
      font-size: 12px;
      padding: 10px 40px;
    }
    & .brr {
      display: block;
    }
    & .mt8 {
      margin-top: 10px !important;
    }
    & .mt3 {
      margin-top: 10px !important;
    }
    & .mr3 {
      margin-right: 0 !important;
    }
    & .mt6 {
      margin-top: 10px !important;
    }
    & .mt4 {
      margin-top: 10px !important;
    }
    & .mt2 {
      margin-top: 10px !important;
    }
    & h1.h1-1 {
      font-size: 24px;
      line-height: 28px;
    }
  }
}