
/* Medium devices (Small desktops 1280px max) */
@media (--medium) {
/*    .my-item {
        width: 33.333333%;
    }*/
    input.datepicker {
        padding: 0 20px;
    }
    .icon-date-to {
        display: none !important;
    }
}

/* Small devices (tablets, 992px max) */
@media (--small) {
    .navbar-header {
        float: none;
        display: inline-block;
    }
    .navbar-default {
        text-align: center;
    }
    .pad-r1 {
        padding-right: 15px !important;
    }
    .pad-l1 {
        padding-left: 15px !important;
    }
}

/* Extra small devices (phones, less than 768px) */
@media (--xsmall) {

    .navbar-header .navbar-brand {
        font-size: 13px;
    }
    .cu-biblestudylogo {
        font-size: 30px;
        line-height: 34px;
    }
    .navbar-default {
        padding: 10px 0;
    }
    .navbar-header {
        display: block;
    }

    .mini-box {
        text-align: center;
    }
    .mini-box .icon {
        display: none;
    }
    .mini-box p {
        font-size: 12px;
    }
    .small-box {
        text-align: center;
    }
    .small-box .icon {
        display: none;
    }
    .small-box p {
        font-size: 0.86rem;
    }
}

