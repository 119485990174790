ul.community-menu li {
    margin: 20px;
}
ul.community-menu.hide-menu li.new-posts {
    margin-left: 0px;
}
ul.community-menu.hide-menu li.new-posts a {
    background: #00b9f7;
    color: #ffffff;
    border-radius: 50px;
}
ul.community-menu.hide-menu li.new-posts a i {
    color: white;
}
@media(--xsmall){
    ul.community-menu.hide-menu li.active {
        margin: 0 20px;
    }
    ul.community-menu.hide-menu li.new-posts {
        position: fixed;
        left: 5px;
        bottom: -10px;
        display: block;
    }
    ul.community-menu.hide-menu li.new-posts a {
        font-size: 0.8rem;
        padding: 0 10px 5px 10px;
        background: #00b9f7;
        color: #ffffff;
        border-radius: 50px;
    }
    ul.community-menu.hide-menu li.new-posts .caret {
        display: none !important;
    }
    ul.community-menu.hide-menu li.new-posts a i {
        font-size: 1rem;
        vertical-align: top;
        color: white;
    }
    .inmplayer-panel-header,
    .inmplayer-trigger {
        background: rgba(0,0,0,0.2) !important;
        box-shadow: none !important;
        color: #ffffff !important;
        font-size: 1rem !important;
        border-radius: 50px !important;
        line-height: 2.2rem !important;
        bottom: 10px !important;
        right: 15px !important;
        border: none !important;
    }
}
ul.community-menu li .caret {
    display: none;
}
@media(--xsmall){
    ul.community-menu li.active .caret {
        display: block;
        position: absolute;
        top: 20px;
        right: 13px;
        color: white;
        border-width: 7px;
    }
    .blog {
        & ul.community-menu li.active .caret {
            top: 17px;
        }
    }
}
ul.community-menu li.active a {
    background-color: #313d47;
    color: white;
    border-radius: 50px;
    -webkit-border-radius: 50px;
}
@media(--xsmall){
    ul.community-menu li.active a {
        background-color: #00b9f7;
    }
}
ul.community-menu li a:hover, ul.community-menu li.active a:hover {
    background-color: #00b9f7;
    color: white;
    border-radius: 50px !important;
    -webkit-border-radius: 50px !important;
}
.community-menu > li > a {
    color: #505050;
    font-size: 0.93rem;
    font-family: ns_widemedium;
    text-transform: uppercase;
    padding: 12px 25px;
}
@media(--medium){
    .community-menu > li > a {
        font-size: 0.8rem;
        padding: 12px;
    }
}
@media(--xsmall){
    .community-menu > li > a {
        color: #77838d;
    }
}
.community-menu > li > a > i {
    color: #878787;
    font-size: 1.43rem;
    vertical-align: sub;
    margin-right: 12px;
}
@media(--xsmall){
    .community-menu > li > a > i {
        color: #77838d;
    }
}
.community-menu > li > a:hover > i,
.community-menu > li.active > a > i {
    color: #ffffff;
}
.new-posts{
    position: fixed !important;
    z-index: 10;
    bottom: -10px;
    opacity: 0.2;
}
.new-posts:hover{
    opacity: 1;
}
.nav-left .divider{
    height: 1px;
    background-color: #e3e3e3;
    margin: 10px 0;
}
@media(--xsmall){
    .nav-left .divider{
        background-color: #4a5d6d;
    }
}
@media(--xsmall){
    .hide-menu li {
        display: none;
    }
    .hide-menu li.active {
        display: block !important;
    }
}