.popup-new {
    position: absolute;
    top: 255px;
    border: 1px solid #cccccc;
    padding: 50px;
    background: white;
    z-index: 99;
    -webkit-box-shadow: 0 0 15px 5px rgba(100,100,100,0.1);
    box-shadow: 0 0 15px 5px rgba(100,100,100,0.1);
    color: #535353;
    font-family: work_sansregular;
    font-size: 1.07rem;
}
@media(--xsmall){
    .popup-new {
        top: auto !important;
        left: auto !important;
        position: relative;
        margin: 0 10px 10px;
        width: auto !important;
        font-size: 1rem;
        padding: 30px;
    }

    .c-popup-autocomplete {
        padding: 5px;
        position: absolute;
        top: 35px !important;
        margin: 0;
    }
}
.j-popup-settings {
    margin-top: -25px;
    margin-left: -290px;
}
@media(--xsmall){
    .j-popup-settings {
        margin-top: 0;
        margin-left: 10px;
    }
}
.popup-create-record {
    width: 250px;
    padding: 0;
}
.popup-create-record .pp-title {
    text-align: left;
    padding: 20px 30px;
    color: #6b6b6b;
}
.popup-arrow {
    position: absolute;
    background: white;
    border-top: 1px solid #cccccc;
    border-left: 1px solid #cccccc;
    right: 7px;
    top: -7px;
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
}
@media(--xsmall){
    .j-popup-compare {
        & .popup-arrow {
            margin-right: -12px;
        }
    }
}
.j-popup-compare .popup-arrow {
    right: 97px;
}
.pop-should-see .popup-arrow {
    left: 153px;
}
.popup-create-record .popup-arrow {
    left:40px;
}
.popup-title {
    text-transform: uppercase;
    color: #010101;
    font-size: 1.43rem;
    font-family: ns_widenormal;
    margin-top: 0;
}
@media(--xsmall){
    .popup-title {
        font-size: 1.23rem;
        margin-bottom: 20px !important;
    }
}
.popup-arrow2 {
    position: absolute;
    background: #00b9f7;
    right: 50%;
    margin-right: -4px;
    bottom: -6px;
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
}
.popup-arrow3 {
    position: absolute;
    background: #F7F7F7;
    border-top: 1px solid #EEEEEE;
    border-left: 1px solid #EEEEEE;
    top: -12px;
    transform: rotate(45deg);
    width: 24px;
    height: 24px;
}
@media(--xsmall){
    .popup-arrow3 {
        background: #e3e3e3;
    }
    hr {
        border-top: 1px solid #a1a1a1;
    }
}
.permonent-pop {
    min-height: 100px;
    background: white;
    border: 1px solid #cccccc;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 0 7px 5px rgba(150,150,150,0.1);
    box-shadow: 0 0 7px 5px rgba(150,150,150,0.1);
}
@media (--xsmall) {
    .permonent-pop {
        position: fixed;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        z-index: 999;
        background: #313d47;
        border: none;
        text-align: center;
    }
}
.pop-should-see {
    min-height: 100px;
    width: 324px;
    background: white;
    border: 1px solid #cccccc;
    position: absolute;
    z-index: 1;
    -webkit-box-shadow: 0 0 7px 5px rgba(150,150,150,0.1);
    box-shadow: 0 0 7px 5px rgba(150,150,150,0.1);
}
@media(--xsmall){
    .pop-should-see {
        width: 246px;
    }
}
.pp-title {
    color: #010101;
    font-family: ns_widenormal;
    font-size: 1.43rem;
    text-align: center;
    padding: 20px 80px;
    text-transform: uppercase;
    position: relative;
}
@media (--xsmall) {
    .pp-title {
        padding: 20px 48px;
        color: #9faab3;
        font-size: 1.16rem;
    }
}
.pop-should-see .pp-title {
    padding: 20px 30px;
}
@media(--xsmall){
    .pop-should-see .pp-title {
        padding: 20px;
    }
}
#popup iframe{
    width: 100%;
}
ul.pp-c-items {
    display: block;
    overflow: hidden;
    padding-left: 0;
    margin: 0;
}
ul.pp-c-items li {
    display: block;
    list-style: none;
}
ul.pp-c-items li a {
    display: block;
    padding: 8px 20px;
    border-top: 1px solid #cccccc;
    text-decoration: none !important;
    font-family: ns_widemedium;
    text-transform: uppercase;
    color: #000000;
    font-size: 1rem;
    text-align: center;
}
@media(--xsmall) {
    ul.pp-c-items li a {
        color: #c9d6e0;
        border-color: #4c5963;
        font-size: 0.8rem;
        text-align: left;
    }
}
ul.pp-c-items li a:hover {
    background: #00b9f7;
    color: white;
}
ul.pp-c-items li a.active {
    background: #313d47;
    color: #00b9f7;
}

/* ------------- Reader - Verse select popup ------------ */
table.t-choose-book {
    width: 100%;
    border: 1px solid #cccccc;
    border-collapse: collapse;
}
table.t-choose-book tr td {
    border: 1px solid #cccccc;
}
table.t-choose-book tr td:first-child {
    border-left: 1px solid transparent;
}
table.t-choose-book tr td:last-child {
    border-right: 1px solid transparent;
}
table.t-choose-book tr:last-child td {
    border-bottom: 1px solid transparent;
}
table.t-choose-book tr td a {
    display: block;
    padding: 8px 20px;
    text-decoration: none !important;
    font-family: ns_widemedium;
    text-transform: uppercase;
    color: #000000;
    font-size: 1rem;
}
table.t-choose-book tr td a:hover {
    background: #00b9f7;
    color: white;
}
table.t-choose-book tr td a.active {
    background: #313d47;
    color: #00b9f7;
}
@media (--xsmall) {
    table.t-choose-book tr td {
        border: 1px solid #4c5963;
    }
    table.t-choose-book tr td a {
        color: #c9d6e0;
        font-size: 11px;
        text-align: left;
    }
}
ul.c-chapter-list {
    display: block;
    overflow: hidden;
    text-align: center;
    padding: 0 18px;
}
ul.c-chapter-list li {
    display: inline-block;
    margin: 2px 0;
}
ul.c-chapter-list li a {
    display: block;
    border: 1px solid #cccccc;
    padding: 10px 0;
    min-width: 45px;
    min-height: 44px;
    text-decoration: none !important;
    color: #000000;
    font-family: ns_widenormal;
    font-size: 1rem;
}
ul.c-chapter-list li a:hover {
    background: #00b9f7;
    color: white;
}
ul.c-chapter-list li a.active {
    background: #313d47;
    color: #00b9f7;
}
@media (--xsmall){
    ul.c-chapter-list {
        display: inline-block;
        overflow: hidden;
        text-align: center;
        padding: 0;
        margin: 0 auto;
        width: 90%;
    }
    ul.c-chapter-list li {
        display: inline-block;
        margin: 2px 0;
    }
    ul.c-chapter-list li a {
        display: block;
        border: 1px solid #4c5963;
        min-width: 30px;
        min-height: 30px;
        text-decoration: none !important;
        color: #c9d6e0;
        padding: 0;
        text-align: center;
        font-size: 11px;
        line-height: 30px;
    }
}
/* Mini Popup */
.bs-example-modal-sm .modal-dialog{
    width: 450px;
}
@media(--xsmall){
    .bs-example-modal-sm .modal-dialog{
        width: auto;
    }
}
.bs-example-modal-sm .modal-dialog .modal-content{
    border-radius: 0;
    border: none;
}
.bs-example-modal-sm .modal-dialog .modal-content .modal-header{
    background-color: #e3e3e3;
    height: 95px;
}
@media(--xsmall){
    .bs-example-modal-sm .modal-dialog .modal-content .modal-header{
        height: auto;
    }
}
.bs-example-modal-sm .modal-dialog .modal-content .modal-body{
    padding: 45px 15px 30px;
}
@media(--xsmall){
    .bs-example-modal-sm .modal-dialog .modal-content .modal-body{
        padding: 20px 15px;
    }
}
.bs-example-modal-sm .modal-dialog .modal-content .modal-footer{
    border-top: 0;
}
.bs-example-modal-sm .modal-dialog .modal-content .modal-header .close{
    margin: 20px 28px;
    font-weight: bold;
    opacity: 1;
    text-shadow: none;
}
@media(--xsmall){
    .bs-example-modal-sm .modal-dialog .modal-content .modal-header .close{
        margin: 9px 5px;
    }
}
.bs-example-modal-sm .modal-dialog .modal-content .modal-header i.bs-close{
    color: #00ade7;
    font-size: 1.57rem;
}
@media(--xsmall){
    .bs-example-modal-sm .modal-dialog .modal-content .modal-header i.bs-close{
        font-size: 1rem;
    }
}
.bs-example-modal-sm .modal-dialog .modal-content .modal-header h4{
    font-size: 2rem;
    color: #505050;
    line-height: 5rem;
    text-transform: uppercase;
    font-family: work_sansregular;
}
@media(--xsmall){
    .bs-example-modal-sm .modal-dialog .modal-content .modal-header h4{
        font-size: 1.5rem;
        line-height: 3rem;
    }
}
.bs-example-modal-sm .modal-dialog .modal-content .modal-body{
    color: #313d47;
    font-family: work_sanssemibold;
    font-size: 1.29rem;
}
@media(--xsmall){
    .bs-example-modal-sm .modal-dialog .modal-content .modal-body{
        font-size: 1.2rem;
        line-height: 1.5rem;
        font-family: work_sansregular;
    }
}
@media(--xsmall) {
    .j-choose-mobile {
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
    }
}
/* Big Popup */
#popup .modal-dialog{
    width: auto !important;
    max-width: 840px;
}
#popup .modal-dialog .modal-content{
    border-radius: 0;
    border: none;
}
#popup .modal-dialog .modal-content .modal-header{
    background-color: #e3e3e3;
    height: 95px;
}
@media(--xsmall){
    #popup .modal-dialog .modal-content .modal-header{
        background-color: #e3e3e3;
        height: auto;
    }
}
#popup .modal-dialog .modal-content .modal-title .s-full-screen-btn{
    margin: 0 45px;
}
@media(--xsmall){
    #popup .modal-dialog .modal-content .modal-title .s-full-screen-btn{
        margin: 0 0 0 25px;
        padding: 5px;
        border-width: 1px;
    }
}
#popup .modal-dialog .modal-content .modal-header .modal-header-btn{
    position: relative;
    top: 12px;
    margin-left: 45px;
    border-left: 1px solid #b7b7b7;
    line-height:initial;
}
@media(--xsmall){
    #popup .modal-dialog .modal-content .modal-header .modal-header-btn{
        margin-left: 0px;
        border-left: none;
    }
}
#popup .modal-dialog .modal-content .modal-header .modal-header-btn i.bs-study{
    vertical-align: text-bottom;
    margin-right: 8px;
}
#popup .modal-dialog .modal-content .modal-header i.bs-note,
#popup .modal-dialog .modal-content .modal-header i.bs-journal,
#popup .modal-dialog .modal-content .modal-header i.bs-pray{
    margin: 16px;
    color: #fcab4b;
    vertical-align: text-top;
    line-height: 2.5rem;
}
@media(--xsmall){
    #popup .modal-dialog .modal-content .modal-header i.bs-note,
    #popup .modal-dialog .modal-content .modal-header i.bs-journal,
    #popup .modal-dialog .modal-content .modal-header i.bs-pray{
        line-height: 1.4rem;
        font-size: 1.4rem;
        margin: 0 10px 0 0;
    }
}
#popup .modal-dialog .modal-content .modal-header i.bs-journal{
    color: #5d93c2;
}
#popup .modal-dialog .modal-content .modal-header i.bs-pray{
    color: #49ba93;
}
#popup .modal-dialog .modal-content .modal-header i.bs-close{
    color: #00ade7;
    font-size: 1.57rem;
}
@media(--xsmall){
    #popup .modal-dialog .modal-content .modal-header i.bs-close{
        font-size: 1.2rem;
    }
}
#popup .modal-dialog .modal-content .modal-header .close{
    margin: 20px 28px;
    font-weight: bold;
    opacity: 1;
    text-shadow: none;
}
@media(--xsmall){
    #popup .modal-dialog .modal-content .modal-header .close{
        margin: 20px 10px;
    }
}
#popup .modal-dialog .modal-content .modal-header .modal-title-text,
#popup .modal-dialog .modal-content .modal-header h4{
    font-size: 2rem;
    color: #505050;
    line-height: 5rem;
    text-transform: uppercase;
}
@media(--small){
    #popup.create-item .modal-dialog .modal-content .modal-header .modal-title-text.pull-left{
        float: none !important;
    }
}
@media(--xsmall){
    #popup .modal-dialog .modal-content .modal-header .modal-title-text,
    #popup .modal-dialog .modal-content .modal-header h4{
        font-size: 1.2rem;
        line-height: 1.2rem;
    }
    #popup.create-item .modal-dialog .modal-content .modal-header .modal-title-text.pull-left{
        float: none;
    }
    .locations {
        & #popup .modal-dialog .modal-content .modal-header .modal-title-text,
        & #popup .modal-dialog .modal-content .modal-header h4{
            padding-top: 22px;
        }
    }
}
#popup .modal-dialog .modal-content .modal-body{
    padding: 0;
}
#popup .modal-dialog .modal-content .modal-body .box-body{
    padding: 40px 40px 0 40px;
}
@media(--xsmall){
    #popup .modal-dialog .modal-content .modal-body .box-body{
        padding: 15px 15px 0 15px;
    }
}
#popup .modal-dialog .modal-content .modal-body .friend-item.box-body{
    padding: 40px;
}
.user-popup h4{
    margin-top: 0;
}
.user-popup .about-me{
    margin-bottom: 10px;
}
.user-popup .cu-cancel {
    font-size: 0.93rem !important;
    padding: 10px !important;
    border-width: 2px;
    border-radius: 0;
    -webkit-border-radius: 0;
    vertical-align: top;
    border-width: 2px !important;
    box-sizing: border-box;
    text-transform: uppercase;
    line-height: 15px;
    margin: 20px 0;
    width: 100%;
    text-align: center;
}