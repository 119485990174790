.blog-cat-title {
    margin: 0 !important;
    font-size: 2.2rem !important;
}
#search-verse input{
    color:#6b6b6b;
    font-weight: bold;
}

#search-verse input::-webkit-input-placeholder {
    color:#6b6b6b;
    font-weight: bold;
}
#search-verse input::-moz-placeholder          {
    color:#6b6b6b;
    font-weight: bold;
}/* Firefox 19+ */
#search-verse input:-moz-placeholder           {
    color:#6b6b6b;
    font-weight: bold;
}/* Firefox 18- */
#search-verse input:-ms-input-placeholder      {
    color:#6b6b6b;
    font-weight: bold;
}

.bg-home #search-verse input::-webkit-input-placeholder {
    color:white;
    font-weight: bold;
}
.bg-home #search-verse input::-moz-placeholder          {
    color:white;
    font-weight: bold;
}/* Firefox 19+ */
.bg-home #search-verse input:-moz-placeholder           {
    color:white;
    font-weight: bold;
}/* Firefox 18- */
.bg-home #search-verse input:-ms-input-placeholder      {
    color:white;
    font-weight: bold;
}

#search-verse button i{
    color:#6b6b6b;
    font-weight: bold;
}

.j-create-order-form input {
    height: 32px;
}
.dropzone .dz-message{
    display: none;
}

.dropzone .dz-preview .dz-image{
    border-radius: 5px;
}

.dropzone .dz-preview {
    margin-right: 0px;
}

.edit-images-thumbs{

}
.edit-images-thumbs .img-thumb{
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
}
.edit-images-thumbs .img-thumb img{
    border-radius: 0px;
}
.location-item, .people-item{
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    padding-bottom: 20px;
    margin-bottom: 15px;
    line-height: 1.86rem;
}
.location-item:last-child, .people-item:last-child, .friend-item:last-child{
    border: 0;
}
.location-item h4, .people-item h4{
    margin-top: 0px;
}
.location-item .slide, .people-item .slide{
    width: 140px;
}
.location-item-details .slide, .people-item-details .slide{
    width: 200px;
}
a.view-details{
    font-weight: bold;
}
a.view-details:hover{
    text-decoration: none;
}
.box{
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    border: 1px solid #ddd;
    border-top: 3px solid #d2d6de;
    margin-bottom: 20px;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}
.box-header {
    color: #444;
    display: block;
    padding: 10px;
    position: relative;
}
.box-header .box-title {
    display: inline-block;
    font-size: 1.29rem;
    margin: 0;
}
.carousel-control{
    background-image: none !important;
}
.box-header>.box-tools {
    position: absolute;
    right: 10px;
    top: 5px;
}
.reset-filter{
    margin-left: 5px;
}
.strongs-page h4{
    padding: 5px 0;
    background: #f5f5f5;
}
.strongs-page .verse-text{
    font-size: 1rem;
}
.text-red{
    color: red;
}
.text-capitalize{
    text-transform: capitalize !important;
}
.text-lowercase{
    text-transform: lowercase !important;
}
.text-uppercase{
    text-transform: uppercase !important;
}
.text-left{
    text-align: left !important;
}
.note-text,.journal-text,.prayer-text,.entry-text,.status-text{
    cursor: pointer;
}
.entry-text a{
    text-decoration: none;
    color: inherit;
}
.no-image{
    width: 140px;
    height: 140px;
}
.location-item-details .no-image{
    width: 200px;
    height: 200px;
}
.no-image .no-image-text{
    height: 130px;
    width: 130px;
    border: 1px solid #f4f4f4;
    line-height: 125px;
    color: #ddd;
}
.location-item-details .no-image .no-image-text{
    height: 190px;
    width: 190px;
    line-height: 12.86rem;
}
.location-item-details .g-map-embed iframe{
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-bottom: 10px;
}
.main-container{
    min-height: 400px;
}
.my-journal-list .box-header,.my-notes-list .box-header, .my-prayers-list .box-header, .my-entries-list .box-header{
    border-bottom: 1px solid #f4f4f4;
}
.my-journal-list .box-body,.my-notes-list .box-body, .my-prayers-list .box-body, .my-entries-list .box-body,.friends-list .box-body, .wall .box-body{
    padding: 10px;
}
.input-daterange input:first-child {
    border-radius: 4px 0 0 4px;
}
.input-daterange input:last-child {
    border-radius: 0 4px 4px 0;
}
.btn-to-reader{
    position: fixed;
    left: 5px;
    top: 125px;
    z-index: 999;
    opacity: 0.3;
    padding: 6px 8px;
    font-size: 0.93rem;
}
.btn-to-reader:hover{
    opacity:1;
}
.related-item .item-header {
    overflow: hidden;
}
.related-item .item-header .fa.fa-pencil, .related-item .item-header .fa.fa-location-arrow{
    color: #337ab7;
    font-size: 1.07rem;
    padding: 2px 0px 2px 2px;
}
.title-verse {
    color: #858585;
    font-family: work_sansmedium;
    font-size: 1.14rem;
}



.fa-book:before {
    content: "\e903";
}
.fa-hand-paper-o:before {
    content: "\e902";
}
.fa-sticky-note:before {
    content: "\e901";
}
.related-item .item-footer{

}
.related-item .item-actions{
    padding: 5px 8px;
}
.related-item .item-actions .popover-content{
    padding: 15px;
}
.related-item .item-actions .like-img{
    margin-right: 3px;
}
.related-item .item-actions .like-item:last-child .like-img{
    margin-right: 0;
}
.item-actions .show-more-likes{
    width: 40px;
    height: 40px;
    font-size: 1.86rem;
    line-height: 1.86rem;
    font-weight: bold;
}



#popup .full-screen-btn{
    font-size: 0.86rem;
    padding: 3px 8px;
    margin: 0 10px;
}

#note-form .fa,#journal-form .fa,#prayer-form .fa{
    color: #90949c;
    width: 1.07rem;
    text-align: center;
}

.my-notes-list .fa, .my-journal-list .fa, .my-prayers-list .fa, .my-entries-list .fa{
    color: #367fa9;
    font-size: 1.4em;
}

.my-entries-list .create-btns .fa{
    color: white;
    font-size: 1em;
}

.my-notes-list h4, .my-journal-list h4, .my-prayers-list h4{
    color: #367fa9;
}

.section-title{
    margin-top: 10px;
    margin-bottom: 30px;
}

/* * Component: Small Box * -------------------- */
.small-box {
    color: white;
    border-radius: 2px;
    position: relative;
    display: block;
    margin-bottom: 20px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.small-box > .inner {
    padding: 10px;
}
.small-box > .small-box-footer {
    position: relative;
    text-align: center;
    padding: 3px 0;
    color: #fff;
    color: rgba(255, 255, 255, 0.8);
    display: block;
    z-index: 10;
    background: rgba(0, 0, 0, 0.1);
    text-decoration: none;
}
.small-box > .small-box-footer:hover {
    color: #fff;
    background: rgba(0, 0, 0, 0.15);
}
.small-box h3 {
    font-size: 2.71rem;
    font-weight: bold;
    margin: 0 0 10px 0;
    white-space: nowrap;
    padding: 0;
}
.small-box p {
    font-size: 15px;
}
.small-box p > small {
    display: block;
    color: #f9f9f9;
    font-size: 0.93rem;
    margin-top: 5px;
}
.small-box h3,
.small-box p {
    z-index: 5;
}
.small-box .icon {
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    position: absolute;
    top: -10px;
    right: 10px;
    z-index: 0;
    font-size: 6.43rem;
    color: rgba(0, 0, 0, 0.15);
}
.small-box:hover {
    text-decoration: none;
    color: #f9f9f9;
}
.small-box:hover .icon {
    font-size: 6.79rem;
}



/* * Component: Mini Box  * -------------------- */
.mini-box {
    color: white;
    border-radius: 2px;
    position: relative;
    display: block;
    margin-bottom: 20px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.mini-box > .inner {
    padding: 5px 15px;
}
.mini-box > .inner .count{
    display: inline-block;
    font-size: 1.86rem;
}

.mini-box > .inner .count-text{
    display: inline-block;
    vertical-align: 0.45ex;
    margin-left: 5px;
}

.mini-box > .mini-box-footer {
    position: relative;
    text-align: center;
    padding: 3px 0;
    color: #fff;
    color: rgba(255, 255, 255, 0.8);
    display: block;
    z-index: 10;
    background: rgba(0, 0, 0, 0.1);
    text-decoration: none;
}
.mini-box > .mini-box-footer:hover {
    color: #fff;
    background: rgba(0, 0, 0, 0.15);
}
.mini-box h3 {
    font-size: 1.86rem;
    font-weight: bold;
    margin: 0 0 10px 0;
    white-space: nowrap;
    padding: 0;
}
.mini-box p {
    font-size: 1.07rem;
}
.mini-box p > small {
    display: block;
    color: #f9f9f9;
    font-size: 0.93rem;
    margin-top: 5px;
}
.mini-box h3,
.mini-box p {
    z-index: 5;
}
.mini-box .icon {
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    position: absolute;
    top: 0px;
    right: 10px;
    z-index: 0;
    font-size: 2.5rem;
    color: rgba(0, 0, 0, 0.15);
}
.mini-box:hover {
    text-decoration: none;
    color: #f9f9f9;
}
.mini-box:hover .icon {
    font-size: 2.71rem;
}
.verse-text.highlight{
    background: #e1e1e8;
}
.related-item.highlight{
    border-color: #367fa9;
    border-width: 2px;
    position: fixed;
    width: 262px;
    top: 100px;
    opacity: 1 !important;
    z-index: 1;
}
.related-item.blur{
    opacity: 0.3;
}
.stars-block{
    position: relative;
    width: 99px;
}
.my-entries-list .stars-block{
    width: 80px;
}
.my-study-item .stars-block {
    position: absolute;
    top: -5px;
    right: 360px;
    line-height: initial;
}
.my-study-verse .stars-block {
    position: absolute;
    top: 15px;
    right: 300px;
    line-height: initial;
}
.stars-block a{
    text-decoration: none;
}
.stars-block .star-link {
    position: relative;
    display: inline-block;
}
.stars-block .star-link.notes {
    position: absolute;
    top: 0;
    left: 31px;
}
.stars-block .star-link.journal {
    position: absolute;
    top: 22px;
    left: 0;
}
.stars-block .star-link.prayers {
    position: absolute;
    top: 22px;
    right: 0;
}
.stars-block .star-link .fa-star {
    font-size: 2.86rem;
    color: #d2d6de;
}
.my-entries-list .stars-block .star-link .fa-star{
    font-size: 2.14rem;
}
.stars-block .star-link .fa-star.active {
    color: #ffd001;
}
.stars-block .star-link .star-label {
    position: absolute;
    top: 12px;
    left: 14px;
    color: white;
    font-weight: bold;
}
.my-entries-list .stars-block .star-link .star-label{
    top: 9px;
    left: 10px;
    font-size: 0.79rem;
}
.stars-block .star-line{
    display: inline-block;
}
.stars-block .star-j,.stars-block .star-n,.stars-block .star-p{
    display: inline-block;
    font-size: 0.86rem;
    font-weight: bold;
    color: #d2d6de;
    padding: 6px 9px;
    width: 30px;
    height: 30px;
    border: 2px solid #d2d6de;
    border-radius: 100%;
}
.stars-block .star-n{
    display: inline-block;
}
.stars-block .star-p{
    display: inline-block;
}
.stars-block .star.active{
    background-color: #ffd001;
    border-color: #ffd001;
    color: #fff;
}
.navbar-nav .fa-home{
    font-size: 1.21rem;
}
.navbar-nav .ion-ios-book{
    font-size: 1.21rem;
}
.navbar-nav .fa-users{
    font-size: 1rem;
}
.navbar-header .navbar-brand{
    padding: 5px 15px;
    line-height: 32px;
    color: #404040;
    font-family: ns_widedemibold;
    font-size: 1.4rem;
}
.home .navbar-header .navbar-brand {
    color: #ffffff;
}
.navbar-inner-pages .navbar-header .navbar-brand{
    padding: 5px 15px 5px 0;
}
@media (--xsmall) {
    .navbar-inner-pages .navbar-header .navbar-brand{
        padding: 5px 15px 5px 15px;
    }
}
.navbar-header .ion-ios-book, .navbar-header .ion-ios-book-outline{
    font-size: 3rem;
    color: #337ab7;
}
#contact-info dd{
    margin-bottom: 20px;
}
.friends-list .friend-item{
    padding: 15px 0;
}
.friends-list .friend-item:last-child{
    border: 0;
}
.cu-btn-copy {
    border-radius: 0 !important;
    height: 45px;
    width: 45px;
    border-color: #00b9f7;
    background: #00b9f7;
    color: white;
}
.cu-label {
    font-size: 1.07rem;
    font-family: work_sansregular;
}
.cu-label2 {
    color: #505050;
    font-family: work_sansmedium;
    font-size: 1.07rem;
}

.cu-btn-copy i.bs-copypaste {
    font-size: 1.43rem;
    padding-top: 5px;
    text-align: center;
    display: inline-block;
}
.cu-btn-copy:hover {
    background: #79858f;
    border-color: #79858f;
    color: white;
}
.checkbox-inline + .checkbox-inline, .radio-inline + .radio-inline {
    margin-left: 0;
}
.group-block{
    border: 1px solid;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    border-radius: 3px;
    margin-bottom: 15px;
}
.group-block .g-header{
    border-top: none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    min-height: 24px;
    line-height: 1.43rem;
    padding: 8px;
    position: relative;
}
.group-block .g-body{
    border-top: 1px solid #e5e5e5;
}
.group-block .g-footer{
    padding: 5px;
    border-top: 1px solid #e5eaf1;
}
.group-block .g-footer:hover{
    background-color: white;
}
.user-block{
    border: 1px solid;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    border-radius: 3px;
    margin-bottom: 15px;
}
.user-block .u-header{
    border-top: none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    min-height: 24px;
    line-height: 20px;
    padding: 8px;
    position: relative;
}
.user-block .u-body{
    border-top: 1px solid #e5e5e5;
}
.user-block .u-footer{
    padding: 5px;
    border-top: 1px solid #e5eaf1;
}
.user-block .u-footer:hover{
    background-color: whitesmoke;
}
.user-block .friend-item{
    padding: 10px;
}
.friend-item .btn{
    padding: 2px 12px;
}
a.disabled,
button.disabled,
a.inactive,
button.inactive{
    opacity: 0.5;
}
.community-menu a.disabled{
    opacity: 0.5;
}
.j-access-level .disabled{
    color: #ccc;
    opacity: 0.5;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #D2D6E1;
}

.img-thumbnail-mini {
    display: inline-block;
    max-width: 100%;
    height: auto;
    padding: 2px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
.img-thumbnail-mini2 {
    display: inline-block;
    max-width: 100%;
    height: auto;
    padding: 2px;
    line-height: 1.42857143;
    background-color: #fff;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 50%;
    -webkit-border-radius: 50%;
}
.wall-like-btn.liked,.wall-like-btn.liked .fa{
    color: #e7567c;
}
.j-item-report.reported,.j-item-report.reported .fa{
    color: #b9b9b9;
}
a {
    color: #00b9f7;
}
a:focus, a:hover {
    color: #272727;
}
.j-item-report.disabled{
    cursor: default;
    opacity: 0.5;
}
.form-control[readonly]{
    background-color: #fff;
}
.hideSearch .select2-search,
.hideSearch .select2-results,
.hideSearch.select2-dropdown,
.hideSearch .select2-dropdown {
    display: none;
}
#invite-form .select2-selection--multiple{
    height: 100px !important;
}
#invite-form .select2-container--open .select2-selection--multiple{
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

/* From temp.css */



/* NAV */
.navbar-nav i.cu-nav-icons{
    font-size: 1.07rem ;
    vertical-align: baseline;
}

.c-menu-home .navbar-nav .dropdown-menu .popup-arrow{
    /*-webkit-background-clip: padding-box;*/
    /*background-clip: padding-box;*/
    border-left: 1px solid #81878b;
    border-top: 1px solid #81878b;
}

.dropdown-menu .popup-arrow{
    transform: rotate(45deg);
}
