.bg-inner-page {
    background: #89bbf2;
    padding: 5px;
}
.inner-container {
    margin: 0 auto;
    padding: 20px 0;
}
@media (--xsmall) {
    .inner-container {
        padding: 0;
    }
}
.in-inner-container {
    padding: 0 50px 30px;
    max-width: 1360px;
    margin: 0 auto;
}
@media (--small) {
    .in-inner-container {
        padding: 0;
    }
    .order.create {
        & .in-inner-container {
            margin: 15px;
        }
    }
}

.bg-g2 {
    min-height: 650px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,e5e6e6+30,e5e6e6+98 */
    background: rgb(255,255,255); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(229,230,230,1) 680px, rgba(229,230,230,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(229,230,230,1) 680px,rgba(229,230,230,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(229,230,230,1) 680px,rgba(229,230,230,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e6e6',GradientType=0 ); /* IE6-9 */
}
@media(--xsmall){
    .bg-g2 {
        min-height: 0;
    }
}
.c-center-content {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -webkit-box-shadow: 0 0 20px 5px rgba(0,0,0,0.1);
    box-shadow: 0 0 20px 5px rgba(0,0,0,0.1);
}
@media (--xsmall) {
    .c-center-content {
        border-radius: 0 !important;
        box-shadow: none;
        -webkit-box-shadow: none;
    }
}
.c-center-content2 {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    background: #f6f6f6;
    min-height: 449px;
}
.c-center-content3 {
    background: rgb(133,203,246); /* Old browsers */
    background: -moz-linear-gradient(left,  rgba(133,203,246,1) 0%, rgba(133,203,246,1) 31%, rgba(148,228,238,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(133,203,246,1) 0%,rgba(133,203,246,1) 31%,rgba(148,228,238,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(133,203,246,1) 0%,rgba(133,203,246,1) 31%,rgba(148,228,238,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#85cbf6', endColorstr='#94e4ee',GradientType=1 ); /* IE6-9 */
}
.c-reader-content, .c-white-content {
    background: #ffffff;
    -webkit-box-shadow: 0 0 30px 2px rgba(100,100,100,0.1);
    box-shadow: 0 0 30px 2px rgba(100,100,100,0.1);
    box-sizing: border-box;
}
@media(--xsmall){
    .community-menu.c-white-content.hide-menu {
        background-color: transparent;
        margin: 0 30px 10px;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .community-menu.c-white-content {
/*        max-width: 260px;*/
        background-color: #313d47;
        margin: 0 30px 10px;
        -webkit-box-shadow: 0 0 15px 3px rgba(0,0,0,0.1);
        box-shadow: 0 0 15px 3px rgba(0,0,0,0.1);
    }
    .community.invite-people .community-menu.c-white-content.hide-menu,
    .groups .community-menu.c-white-content.hide-menu {
        margin: 0 30px 30px;
    }
    .groups .group-form .c-white-content{
        min-height: 567px !important;
    }
    .community.invite-people .select2-container{
        width: 100% !important;
    }
    .auth.login {
        & .c-white-content {
            margin: 10px;
            min-height: 567px !important;
        }
    }
    .password.reset {
    & .c-white-content {
          margin: 10px;
          min-height: 567px !important;
      }
    }
    .blog {
        & .c-blog {
            min-height: 567px !important;
        }
    }
    .shop.product {
        & .c-white-content {
            margin: 15px;
        }
    }
}
@media(--vxsmall){
    .auth.login {
        & .c-white-content {
            min-height: 10px !important;
        }
    }
    .password.reset {
        & .c-white-content {
              min-height: 10px !important;
          }
    }
    .blog {
        & .c-blog {
            min-height: 10px !important;
        }
    }
}
.c-reader-content2 {
    background: #ffffff;
    -webkit-box-shadow: 0 0 30px 2px rgba(100,100,100,0.1);
    box-shadow: 0 0 30px 2px rgba(100,100,100,0.1);
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    right: 15px;
}

.c-title-and-icons {
    padding: 0 165px;
    position: relative;
}
@media (--small) {
    .c-title-and-icons {
        padding: 0 5px;
        position: relative;
    }
}
.c-title-and-icons2 {
    padding: 0 270px;
    position: relative;
    text-align: center;
}
.c-title-and-icons3 {
    padding: 0 50px;
    position: relative;
    text-align: center;
}
@media(--xsmall){
    .c-title-and-icons3 {
        padding: 0 35px;
    }
}
.c-title-and-icons4 {
    padding: 0 150px;
    position: relative;
    text-align: center;
}

.inner-pad1 {
    padding: 40px 45px;
    line-height: 2.14rem;
    position: relative;
}
@media(--xsmall) {
    .inner-pad1 {
        padding: 15px 20px;
        line-height: 1.46rem;
    }
}
.inner-pad2 {
    padding: 35px 45px;
    line-height: 2.14rem;
    position: relative;
}
@media(--xsmall){
    .inner-pad2 {
        padding: 15px 20px;
        line-height: 2.14rem;
        position: relative;
    }
}
.inner-pad5 {
    padding: 80px 45px;
    line-height: 2.14rem;
    position: relative;
}
@media(--xsmall){
    .login {
        & .inner-pad5 {
            padding: 20px;
        }
    }
    .password.reset {
        & .inner-pad5 {
              padding: 20px;
          }
    }
}
.c-white-item {
    background: white;
    -webkit-box-shadow: 0 0 10px 2px rgba(150,150,150,0.1);
    box-shadow: 0 0 10px 2px rgba(150,150,150,0.1);
    position: relative;
}
.inner-pad3 {
    padding: 25px;
}
@media(--xsmall){
    .inner-pad3 {
        padding: 20px;
    }
}
.inner-pad6 {
    padding: 10px;
}
.pad2 {
    padding: 100px 10px;
}
@media (--xsmall) {
    .container-fluid {
        padding-right: 0;
        padding-left: 0;
        margin-right: 0;
        margin-left: 0;
        overflow-x: hidden;
    }
}
.locations.view .g-map-embed iframe{
    width: 100%;
}
.locations.view .detail-view-carousel{
    width: 340px;
    float: left;
    margin: 0 20px 20px 0;
}

@media (--xsmall) {
    .locations.view .detail-view-carousel{
        width: 100%;
        float: none;
        margin: 0 20px 20px 0;
    }
    .locations.view .m-btn,
    .peoples.view .m-btn{
        background: white;
        margin: 0px 0px 15px !important;
        padding: 10px 0px;
        z-index: 9;
        position: relative;
    }
}