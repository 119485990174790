.mt0 {
    margin-top: 8px !important;
}
.mt1 {
    margin-top: 100px !important;
}
.mt2 {
    margin-top: 70px !important;
}
.mt3 {
    margin-top: 40px !important;
}
.mt4 {
    margin-top: 90px !important;
}
.mt5 {
    margin-top: 20px !important;
}
.mt6 {
    margin-top: 65px !important;
}
.mt7 {
    margin-top: 100px !important;
}
.mt8 {
    margin-top: 40px !important;
}
.mt9 {
    margin-top: 25px !important;
}
.mt10 {
    margin-top: 60px !important;
}
.mt11 {
    margin-top: 100px !important;
}
.mt12 {
    margin-top: 90px !important;
}
.mt13 {
    margin-top: 30px !important;
}
.mt14 {
    margin-top: 40px !important;
}
.mt15 {
    margin-top: 10px !important;
}
@media(--xsmall){
    .mt15 {
        margin-top: 6px !important;
    }
}
.mt16 {
    margin-top: 15px !important;
}
.mt17 {
    margin-top: 25px !important;
}
.mt18 {
    margin-top: 40px !important;
}
.mb1 {
    margin-bottom: 50px;
}
@media(--xsmall){
    .groups.view {
        & .mb1 {
            margin-bottom: 0;
        }
    }
}
.mb2 {
    margin-bottom: 100px;
}
@media(--xsmall){
    .contact .mb2 {
        margin-bottom: 20px;
    }
}
.mb3 {
    margin-bottom: 50px;
}
@media(--xsmall){
    .reader.verse {
        & .mb3 {
            margin-bottom: 20px;
        }
    }
}
.mb4 {
    margin-bottom: 20px !important;
}
@media(--xsmall){
    .reader.strongs {
        & .mb4 {
            margin-bottom: 70px !important;
        }
    }
}
.mb5 {
    margin-bottom: 15px !important;
}
.mb6 {
    margin-bottom: 6px !important;
}
.mr1 {
    margin-right: 10%;
}
.mr2 {
    margin-right: 35%;
}
.mr3 {
    margin-right: 32%;
}
.mr4 {
    margin-right: 6%;
}
.mr5 {
    margin-right: 10px;
}
.mr2p {
    margin-right: 2%;
}
.mr4p {
    margin-right: 4%;
}
.mr6 {
    margin-right: 5px;
}
.mr7 {
    margin-right: 15px;
}
.ml1 {
    margin-left: 10px;
}
.m-btn {
    margin-top: 40px !important;
    margin-bottom: 50px !important;
}
.m-cu1 {
    margin-bottom: 15px;
    margin-top: 25px;
}
.pad1 {
    padding-bottom: 50px;
}
.cu-btn-pad1 {
    padding: 14px 12px !important;
}
@media(--xsmall){
    .cu-btn-pad1 {
        padding: 5px 20px !important;
    }
}
.pad-l1 {
    padding-left: 40px;
}
.pad-r1 {
    padding-right: 40px;
}
.cu1-h2 {
    padding: 0 200px;
    margin: 0 0 60px;
}
@media(--xsmall){
    .cu1-h2 {
        padding: 0;
        margin: 0 0 40px;
    }
}
.fa-btn {
    margin-right: 6px;
}

.read-more-mt{
    margin-top: 7px;
}