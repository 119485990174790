.user-image {
    width: 60px;
    height: 60px;
    position: absolute;
    top: -2px;
    left: 10px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    overflow: hidden;
    background: url('/images/default.jpg') center center no-repeat;
    background-size: cover !important;
    -webkit-background-size: cover !important;
}
@media(--xsmall){
    .user-image {
        width: 48px;
        height: 48px;
    }
}
.friend-image {
    width: 100%;
    height: 177px;
    position: relative;
    overflow: hidden;
    background: url('/images/default.jpg') center no-repeat;
    background-size: cover !important;
    -webkit-background-size: cover !important;
}
.group-image {
    width: 100%;
    height: 177px;
    position: relative;
    overflow: hidden;
    background: url('/images/default-group.png') center center no-repeat;
    background-size: cover !important;
    -webkit-background-size: cover !important;
}
@media(--xsmall){
    .friend-image ,
    .group-image {
        height: 280px;
    }
}
.people-image {
    /*display: none;*/
    width: 100%;
    height: 260px;
    position: relative;
    overflow: hidden;
    background: url('/images/default.jpg') center no-repeat;
    background-size: cover !important;
    -webkit-background-size: cover !important;
}
.location-image {
    width: 100%;
    height: 260px;
    position: relative;
    overflow: hidden;
    /*background: url('/images/default.jpg') center no-repeat;*/
    background-size: cover !important;
    -webkit-background-size: cover !important;
    padding: 60px;
    background-color: #79858F;
    background-position-y: top !important;
}
@media(--xsmall){
    .location-image {
        height: 280px;
        padding: 60px 20px 0;
    }
}
.location-image i{
    font-size: 10rem;
    color: #D2D7DC;
}
@media(--xsmall){
    .location-image i{
        font-size: 8rem;
    }
}
.verse-image i{
    font-size: 5rem;
}
@media(--xsmall){
    .reader.verse {
        & .verse-image i {
            font-size: 7rem;
        }
    }
}
.product-image {
    width: 100%;
    height: 280px;
    position: relative;
    overflow: hidden;
    background: #79858f;
    background-size: cover !important ;
    -webkit-background-size: cover !important;
    cursor: pointer;
    border-bottom: 1px solid #e3e3e3;
    display: block;
}
.product-image-big {
    width: 100%;
    height: 340px;
    position: relative;
    overflow: hidden;
    background: #79858f;
    background-size: cover !important;
    -webkit-background-size: cover !important;
    border-bottom: 1px solid #e3e3e3;
    display: block;
}
.img-thumbnail {
    border-radius: 0;
    border: 1px solid #e8e8e8;
    width: 97px;
    height: 97px;
    padding: 2px;
}
@media(--xsmall){
    .img-thumbnail {
        border-radius: 0;
        border: 1px solid #e8e8e8;
        width: 100%;
        height: auto;
        padding: 2px;
    }
}
.verse-image {
    width: 170px;
    height: 184px;
}
@media(--xsmall){
    .reader.verse {
        & .verse-image {
            width: 100%;
            height: 220px;
            text-align: center;
        }
    }
}
.user-avatar {
    border-radius: 50%;
}
.cu-ui2 {
    height: 42px !important;
    width: 42px !important;
    left: 0px !important;
    top: 0 !important;
}
/* ------- delete people photo */
.del-photo .cu-ci1-inner {
    display: none;
}
.del-photo.cu-ci1 {
    padding-left: 0 !important;
}
.del-photo2 {
    display: none !important;
}
/* User avatar in profile */
#avatar.dropzone {
    width: 140px;
    max-height: 140px;
    min-height: 140px;
    border: 1px dotted #a9a9a9;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    overflow: hidden;
    padding: 0;
}
#avatar.dropzone .dz-preview .dz-image {
    width: 140px !important;
    height: 140px !important;
}
#avatar.dropzone .dz-preview{
    margin: 0;
}
#avatar.dropzone .select-image{
    position: absolute;
    left: 0;
    padding: 41px 45px !important;
}
.no-avatar {
    width: 100px;
    height: 100px;
}
.no-avatar-midi {
    width: 54px;
    height: 54px;
}
.no-avatar-mini {
    width: 40px;
    height: 40px;
}
.no-avatar-mini2 {
    width: 30px;
    height: 30px;
}
.no-avatar .no-avatar-text {
    height: 90px;
    width: 90px;
    line-height: 8.92rem;
    color: #ddd;
}
.no-avatar-mini .no-avatar-text {
    height: 34px;
    width: 34px;
    line-height: 3rem;
    color: #ddd;
}
.no-avatar-midi .no-avatar-text {
    height: 44px;
    width: 44px;
    border: 1px solid #f4f4f4;
    line-height: 3.71rem;
    color: #ddd;
}
.c-cont-w-avatar {
    padding-left: 165px;
    position: relative;
}
@media(--xsmall){
    .user.profile .c-cont-w-avatar  {
        padding-left: 0px;
    }
}
.left-avatar {
    position: absolute;
    left: 0;
    top: 16px;
}
@media(--xsmall){
    .user.profile .left-avatar {
        position: relative;
        left: 0;
        top: 0px;
    }
    .user.profile .left-avatar #avatar {
        margin: 0 auto;
    }
}
.remove-image {
    position:absolute;
    top: 43px;
    left: 48px;
    color: #f4645f;
    cursor: pointer;
}