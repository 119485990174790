.urls-text {
    color: #333333;
    font-family: work_sansregular;
    font-size: 1.14rem;
}
.iu-text {
    color: #00b9f7;
    text-decoration: none !important;
}
.il-text {
    color: #00b9f7;
    text-decoration: underline;
}