.group-details{
    height: 250px;
    margin-bottom: 15px;
    position: relative;
}

.group-details .header{
    height: 175px;
    overflow: hidden;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.89+0,0.31+43,0.06+72,0+100 */
    background: -moz-linear-gradient(top,  rgba(0,0,0,0.89) 0%, rgba(0,0,0,0.31) 43%, rgba(0,0,0,0.06) 72%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0.89) 0%,rgba(0,0,0,0.31) 43%,rgba(0,0,0,0.06) 72%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,0.89) 0%,rgba(0,0,0,0.31) 43%,rgba(0,0,0,0.06) 72%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e3000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

@media(--xsmall){
    .group-details{
        margin-bottom: 0px;
    }
}

@media(--vxsmall2){
    .group-details{
        margin-bottom: 0px;
        height: auto;
        position: relative;
        padding-top: 61%;
    }
    .group-details .header {
        height: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        position: absolute;
    }
}

.group-details .title{
    color: white;
    font-family: ns_widemedium;
    font-size: 1.29rem;
    padding: 10px 30px;
    text-transform: uppercase;
}
@media(--xsmall){
    .group-details .title {
        padding: 10px 10px;
        text-align: center;
    }
}
.group-details .title i.bs-s-groups {
    font-size: 1.57rem;
    margin-right: 8px;
    vertical-align: text-bottom;
}
.group-details .controls {
    color: #00b9f7 !important;
    padding: 0 0 0 20px;
}
@media(--xsmall){
    .group-details .controls {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        text-align: center;
    }
}
.group-details .controls a{
    margin-left: 5px;
    text-decoration: none !important;
}
@media(--xsmall){
    .group-details .controls a{
        display: inline-block;
        float: none !important;
    }
}
.group-details .controls a i.bs-remove,
.group-details .controls a i.bs-settings {
    color: #00b9f7;
    font-size: 1.64rem;
    margin-left: 10px;
    text-decoration: none !important;
}
.group-details .controls a:hover i {
    color: #ffffff;
}
.group-details .controls .fa-trash,.group-details .controls .fa-cog{
    color: whitesmoke;
    font-size: 1.71rem !important;
}
.widget-group-title {
    font-family: work_sanssemibold;
    font-size: 1.14rem;
    color: #272727;
    text-transform: capitalize;
    position: relative;
    padding-right: 50px;
}
.widget-group-title2 {
    font-family: work_sanssemibold;
    font-size: 1.14rem;
    color: #272727;
    text-transform: capitalize;
    position: relative;
    margin-top: 10px;
    margin-bottom: 5px;
}
.count-members {
    position: absolute;
    top: 0;
    right: 0;
    color: #a9a9a9;
    font-family: work_sansmedium;
    font-size: 0.93rem;
    text-decoration: none !important;
}
.count-members:hover {
    color: #010101;
}
.c-description-w {
    font-family: work_sansregular;
    color: #535353;
    font-size: 1.14rem;
    line-height: 1.57rem;
    margin-top: 10px;
    overflow: hidden;
}
.group-info .info-block{
    margin-bottom: 15px;
}
.groups.view {
    & .btns-panel {
        position: relative;
        float: left;
        top: 0;
    }
}
@media(--vxsmall2){
    .group-details .controls .group-controls{
        width:100%;
        margin-top: 15px;
    }
}
@media(--xsmall){
    .group-details .controls .group-controls{
        margin-right: 20px;
    }
}
@media(--vxsmall2){
    .group-details .btns-panel {
        float: none !important;
    }
}
