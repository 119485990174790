.color1 {
    color: #ffffff !important;
}
.color2 {
    color: #404040 !important;
}
.color3 {
    color: #000000 !important;
}
.color4 {
    color: #010101 !important;
}
.color5 {
    color: #49ba93 !important;
}
.color6 {
    color: #6196c3 !important;
}
.color7 {
    color: #fea841 !important;
}
.color8 {
    color: #a9a9a9 !important;
}
.color9{
    color: #00b9f7 !important;
}
.bg-color-transparent{
    background-color: transparent;
}