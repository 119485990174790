.bl-my-journey {
    font-size: 14px;

    @media (max-width: 1340px) {
      & {
        font-size: 13px;
      }
    }

    @media (max-width: 1280px) {
      & {
        font-size: 12px;
      }
    }

    @media (max-width: 1220px) {
      & {
        font-size: 11px;
      }
    }

    @media (max-width: 1160px) {
      & {
        flex-direction: column;
      }
    }

    @media (--small) {
      & {
        margin: 10px;
      }
    }


    & .profile {
        background-color: #fff;
        display: flex;
        align-items: stretch;
        justify-content: flex-end;
        box-shadow: 0 0 30px 2px rgba(100,100,100,0.1);
        flex-wrap: wrap;

        @media (max-width: 1150px) {
            margin-top: 50px;
        }

        & .user {
            margin-right: auto;
            padding: 22px 32px;
            display: flex;
            align-items: center;

            & .userpic {
                width: 60px;
                height: 60px;
                background-size: cover;
                border-radius: 50%;
                background-position: center center;
                background-repeat: no-repeat;

                &.no-photo {
                    background-image: url('/images/default.jpg');
                }
            }

            & .user-info {
                margin-left: 20px;
                font-family: 'work_sansmedium';

                & .name {
                    color: #505050;
                    font-size: 1.29em;
                }

                & .since {
                    font-family: 'work_sansmedium';
                    color: #a9a9a9;
                    font-size: 0.93em;
                }

                & .relations {
                    font-family: 'work_sansmedium';
                    color: #a9a9a9;
                    font-size: 0.93em;

                    & .friends {
                        margin-right: 20px;
                    }
                }
            }

            @media (max-width: 1150px) {
                & {
                    width: 100%;
                    flex-direction: column;
                    align-items: center;
                    margin-top: -53px;
                    font-size: 1.3em;
                    line-height: 1.7em;

                    & .user-info {
                        text-align: center;
                        margin-left: 0;
                        margin-top: 10px;
                    }
                }
            }

        }

        & .counters {
            display: flex;

            @media (max-width: 1150px) {
              & {
                flex-grow: 1;
              }
            }

        }

        & .counter {
            min-width: 164px;
            border-left: 1px solid #e3e3e3;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 22px;
            flex: 1;

            & .count {
                font-size: 3.57em;
                font-family: 'ns_widenormal';
                line-height: 1em;
                padding-bottom: 10px;


                & i {
                    font-size: 0.6em;
                }
            }

            &.notes {
                color: #fea841;
            }

            &.entries {
                color: #6196c3;
            }

            &.prayers {
                color: #49ba93;
            }

            @media (max-width: 1150px) {
                & {
                    border-top: 1px solid #e3e3e3;
                    padding: 10px 0;
                    min-width: auto;

                    &:first-child {
                        border-left: none;
                    }
                }
            }

        }

        & .create-record {
            all: initial;
            /*min-width: 255px;*/
            position: relative;

            & .button {
                color: white;
                text-decoration: none;
                box-sizing: border-box;
                background-color: var(--mainColor);
                display: flex;
                align-items: center;
                padding: 28px 32px;
                font-size: 1.29em;
                font-family: 'ns_widenormal';
                text-transform: uppercase;
                height: 100%;

                & i {
                    margin-right: 5px;
                    font-size: 1.4em;
                }

                &:hover {
                    background-color: #79858f;
                }

                @media (--xsmall) {
                    & {
                        justify-content: center;
                        padding: 20px;
                    }
                }
            }

            @media (--xsmall) {
                & {
                    width: 100%;
                }
            }

            & .dropdown {
                position: absolute;
                border: 1px solid #cccccc;
                background: white;
                box-shadow: 0 0 15px 5px rgba(100,100,100,0.1);
                left: 0;
                right: 0;
                top: calc(100% + 20px);
                font-family: 'work_sansregular';
                font-size: 1.07em;
                z-index: 1000;
                padding-bottom: 10px;
                display: none;

                &:before {
                  content: '';
                  border-top: 1px solid #cccccc;
                  border-left: 1px solid #cccccc;
                  transform: rotate(45deg);
                  left: 35px;
                  top: -7px;
                  width: 12px;
                  height: 12px;
                  background-color: white;
                  position: absolute;
                }

                & .title {
                    text-align: left;
                    padding: 20px 30px;
                    color: #6b6b6b;
                    font-family: 'ns_widenormal';
                    font: 1.4em;
                    text-transform: uppercase;
                }

                & ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;

                    & a {
                        text-transform: uppercase;
                        font-family: 'ns_widedemibold';
                        font-size: 1rem;
                        padding: 11px 30px;
                        display: block;
                        line-height: 1rem;
                        color: #6b6b6b;
                        text-decoration: none;

                        &:hover {
                            background-color: var(--mainColor);
                            color: white;

                            & i {
                                color: white;
                            }
                        }

                        & i {
                            color: var(--mainColor);
                            margin-right: 8px;
                        }
                    }
                }

            }

        }

        &.opened {

            & .create-record .dropdown {
                display: block;
            }

            @media (--xsmall) {
                & {
                    margin-bottom: 220px;

                    & .create-record .dropdown:before {
                        left: 50%;
                        margin-left: -7px;
                    }
                }
            }
        }
    }
}

.bl-filters {
    margin-top: 15px;
}

@media (--xsmall) {
    .journey-list.journey-list {

        & li {
            padding: 20px 20px 20px 70px;
        }

        & .c-verse-icon3 {
            width: 30px;
            height: 30px;
            top: 20px;
            left: 20px;
            font-size: 1.2rem;
            line-height: 2.1rem;
        }

        & .c-journey-tags {
            float: none;
        }

        & .c-journey-relations {
            float: none;
        }

        & .c-journey-top {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
        }



        & .c-journey-date {
            text-align: right;
        }

        & .i-ansvered {
            white-space: nowrap;
        }

        & .link {
            white-space: nowrap;
        }

    }

    .bl-filters {
        & .c-white-block {
            margin: 11px;
        }

        & .adv-search {
            width: 30px;
            overflow: hidden;
            padding: 0 10px;
            color: transparent;

            & i {
                color: var(--mainColor);
            }
        }

    }
}
@media(--vxsmall){
    .journey-list.journey-list {
        & .type {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}
