.over-iframe{
    position: absolute;
    width: 90%;
    height: 100%;
    z-index: 1;
    background-color: #000000;
    opacity: 0.15;
    left: 5%;
    right: 5%;
}
.price-label {
    padding: 20px 0 5px;
    font-size: 1.43rem;
    font-family: work_sansbold;
    color: #535353;
}
.price-label2 {
    padding: 0px;
    font-size: 1.43rem;
    font-family: work_sansbold;
    color: #535353;
}
.cu-cart {
    font-size: 1.36rem;
    line-height: 2.21rem;
}
@media(--xsmall){
    .cu-cart {
        font-size: 1rem;
        line-height: 1rem;
    }
}
.cu-cart2 {
    font-size: 0.86rem;
    padding-right: 2px;
}
.cart-desktop {
    display: table;
}
.cart-mobile {
    display: none;
}
@media(--xsmall){
    .cart-desktop {
        display: none;
    }
    .cart-mobile {
        display: block;
    }
    .shop.product {
        & .form-group {
            margin-top: 20px;
            & select {
                width: 100% !important;
                max-width: 300px;
            }
        }
        & .text-right {
            text-align: center;
            & a:first-child {
                margin-left: 0 !important;
            }
        }
    }
    .shop.cart {
        & .c-white-item {
            margin: 15px;
            padding: 15px;
        }
        & .img-thumbnail {
            width: 100% !important;
            height: auto;
        }
        & h3 {
            font-size: 1.3rem;
            font-family: work_sansmedium;
        }
        & h3.h3-kit {
            font-size: 1.5rem;
            padding: 10px 0;
        }
        & .c-resp-checkout {
            padding: 0 15px;
        }
        & .mb-resp {
            margin-bottom: 20px;
            border-bottom: 1px solid #e8e8e8;
        }
    }
}