h1.h1-1 {
    font-size: 3.43rem;
    line-height: 4.43rem;
    color: #404040;
    display: block;
    text-align: center;
    margin: 0;
    font-family: ns_widelight;
}
h1.h1-1 span {
    font-family: ns_widenormal;
}
h2.h2-3 {
    font-size: 1.43rem;
    color: #7b828a;
    display: block;
    text-align: center;
    margin: 10px 0;
    font-family: work_sansregular;
}
h3.h3-1 {
    font-size: 1.43rem;
    color: #7b828a;
    display: block;
    text-align: center;
    margin: 10px 0;
    font-family: work_sansregular;
}
h2.h2-1 {
    /*font-size: 2.71rem;*/
    font-size: responsive 12px 38px;
    color: #404040;
    display: block;
    font-family: ns_widelight;
    text-transform: uppercase;
}
h2.h2-1 span {
    font-family: ns_widenormal;
}
p.p-1 {
    font-family: work_sansregular;
    color: #7b828a;
    font-size: 1.29rem;
    line-height: 1.43rem;
}
h2.h2-2 {
    font-size: 2.71rem;
    line-height: 3.14rem;
    color: #313131;
    display: block;
    text-align: center;
    margin: 0;
    font-family: ns_widenormal;
    text-transform: uppercase;
}
p.p-2 {
    font-family: work_sansregular;
    font-size: 1.64rem;
    color: #7b828a;
    line-height: 2rem;
}
p.p-2 span.ital {
    font-style: italic;
}
h4.h4-1 {
    font-size: 1.64rem;
    color: #000000;
    font-family: ns_widenormal;
    text-transform: uppercase;
    padding-bottom: 15px;
}
.verse-text {
    color: #535353;
    font-family: work_sansregular;
    font-size: 1.29rem;
}
@media (--xsmall) {
    .verse-text {
        color: #535353;
        font-family: work_sansregular;
        font-size: 0.91rem;
        line-height: 1.46rem;
    }
}
.verse-search-text {
    color: #535353;
    font-family: work_sansregular;
    font-size: 1.29rem;
    text-align: justify;
}
.h4-sel-version {
    display: inline-block;
    text-align: center;
    color: #010101;
    font-family: ns_widenormal;
    font-size: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: relative;
    padding: 0 20px;
    cursor: pointer;
    margin-bottom: 10px;
}
@media (--xsmall) {
    .h4-sel-version {
        font-size: 0.6rem;
        line-height: 1.4rem;
        margin: 2px;
    }
}
.h4-sel-book {
    display: inline-block;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: relative;
    padding: 0 20px;
    cursor: pointer;
    margin-bottom: 10px;
    color: #010101;
    font-family: ns_widemedium;
    font-size: 2.14rem;
    margin: 0;
}
@media (--xsmall) {
    .h4-sel-book {
        font-size: 1.29rem;
        /*line-height: 1.4rem;*/
    }
}
.cu-date1 {
    float: right !important;
    color: #a9a9a9;
    font-family: work_sansregular;
    font-size: 1.14rem !important;
}
@media(--xsmall){
    .cu-date1 {
        font-size: 1rem !important;
    }
}
.cu-date2 {
    color: #a9a9a9;
    font-family: work_sansregular;
    font-size: 1.14rem;
}
.inner-pad3 p {
    margin: 0;
}
.highlight{
    color: #00b9f7;
    font-family: work_sansbold;
    border-bottom: 1px solid #00b9f7;
}
.medium {
    font-family: work_sansmedium;
}
.show-more{
    cursor: pointer;
}
.show-more:hover{
    color: #00b9f7 !important;
}
.font-size-12 {
    font-size: 0.86rem;
}
.font-size-13 {
    font-size: 0.93rem;
}
@media(--xsmall){
    .font-size-13 {
        font-size: 0.65rem;
    }
}
.font-size-16 {
    font-size: 1.14rem;
}
.font-size-18 {
    font-size: 1.29rem;
}
.font-size-20 {
    font-size: 1.43rem;
}
@media(--xsmall){
    .font-size-20 {
        font-size: 1rem;
    }
}
.font-size-22 {
    font-size: 1.57rem;
}
@media(--xsmall){
    .font-size-22 {
        font-size: 0.9rem;
    }
    .compare-mode .lex-content .medium{
        margin-bottom: 5px;
    }
    .compare-mode .lex-content .lex-verse-part{
        margin-top: 5px;
    }
}
h1.h1-kit {
    font-family: ns_widenormal;
    font-size: 3.43rem;
    color: #404040;
    text-transform: uppercase;
    font-weight: normal;
    margin: 0;
    padding: 0;
}
h2.h2-kit {
    font-family: ns_widenormal;
    font-size: 2.7rem;
    color: #404040;
    text-transform: uppercase;
    font-weight: normal;
    margin: 0;
    padding: 0;
}
h3.h3-kit {
    font-family: ns_widemedium;
    font-size: 2.14rem;
    color: #404040;
    text-transform: uppercase;
    font-weight: normal;
    margin: 0;
    padding: 0;
    letter-spacing: 2px;
}
@media(--xsmall){
    .shop.product {
        & h3.h3-kit {
            margin-top: 20px;
        }
    }
    .order.show {
        & h3.h3-kit {
            padding: 20px 0 10px;
            font-size: 1.4rem;
        }
        & p {
           font-size: 1rem;
        }
    }
}
h4.h4-kit {
    font-family: ns_widenormal;
    font-size: 1.43rem;
    color: #404040;
    text-transform: uppercase;
    font-weight: normal;
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
}
h5.h5-kit {
    font-family: ns_widenormal;
    font-size: 1.14rem;
    color: #404040;
    text-transform: uppercase;
    font-weight: normal;
    margin: 0;
    padding: 0;
}
h6.h6-kit {
    font-family: ns_widenormal;
    font-size: 1rem;
    color: #404040;
    text-transform: uppercase;
    font-weight: normal;
    margin: 0;
    padding: 0;
}

h1.h1-sub-kit {
    font-family: work_sansregular;
    font-size: 2.71rem;
    color: #404040;
    font-weight: normal;
    margin: 0;
    padding: 0;
}
h2.h2-sub-kit {
    font-family: work_sansregular;
    font-size: 2.14rem;
    color: #404040;
    font-weight: normal;
    margin: 0;
    padding: 0;
}
h3.h3-sub-kit {
    font-family: work_sanssemibold;
    font-size: 1.29rem;
    color: #404040;
    font-weight: normal;
    margin: 0;
    padding: 0;
}
h4.h4-sub-kit {
    font-family: work_sanssemibold;
    font-size: 1.07rem;
    color: #404040;
    font-weight: normal;
    margin: 0;
    padding: 0;
}
@media(--xsmall){
    h4.h4-sub-kit {
        line-height: 1.4rem;
    }
}
.p-regular {
    color: #535353;
    font-family: work_sansregular;
    font-size: 1.07rem;
    line-height: 22px;
}
.p-medium {
    color: #535353;
    font-family: work_sansregular;
    font-size: 1.14rem;
    line-height: 2.14rem;
}
.p-medium2 {
    color: #535353;
    font-family: work_sansregular;
    font-size: 1.14rem;
    line-height: 1.43rem;
}
.p-big {
    color: #535353;
    font-family: work_sansregular;
    font-size: 1.29rem;
    line-height: 2.29rem;
}
.cu1-title {
    text-align: center;
    padding: 30px 30px 50px !important;
}
.cu2-title {
    display: inline-block;
    padding: 0 40px !important;
}
.fsz1 {
    font-size: 2.57rem !important;
    line-height: 2.5rem;
}
.fz1 {
    font-size: 1.71rem !important;
}
.c-queries {
    font-size: 4.29rem;
    font-family: work_sansregular;
    color: #000000;
    position: absolute;
    top: 70px;
    left: 50%;
    margin-left: -10px;
}
.h2-new {
    position: relative;
    color: #010101;
    font-size: 2.14rem;
    font-family: ns_widemedium;
    font-weight: normal;
    text-align: center;
    display: block;
    text-transform: uppercase;
    letter-spacing: 2px;
}
@media(--xsmall){
    .h2-new {
        font-size: 1.27rem;
        letter-spacing: 1px;
    }
    .login {
        & .h2-new {
            font-size: 2rem;
            line-height: 2.5rem;
        }
    }
    .password.reset {
        & .h2-new {
              font-size: 2rem;
              line-height: 2.5rem;
          }
    }
}
.sub-text-radio {
    font-family: work_sansregular;
    color: #a1a1a1;
    font-size: 0.93rem;
    line-height: 0.93rem;
}
@media(--medium){
    .resp-text-center {
        text-align: center;
        padding: 0 15px;
    }
}