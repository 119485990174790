table.kit-table1 {
    width: 100%;
    max-width: 100%;
    background: white;
    margin-bottom: 30px;
    -webkit-box-shadow: 0 0 30px 2px rgba(100,100,100,0.1);
    box-shadow: 0 0 30px 2px rgba(100,100,100,0.1);
}
@media(--xsmall){
    .order.show {
        & table.resp-table {
            width: 100% !important;
            float: none;
            display: table;
            margin-bottom: 15px;
        }
        & table.kit-table1 tbody,
        & table.kit-table1 thead,
        & table.kit-table1 tr {
            width: 100% !important;
        }
        & .resp-table {
            display: block;
        }
        & .c-content-order {
            clear: both;
            margin: 15px;
            padding: 15px;
            overflow: hidden;
        }
        & .c-white-item {
            margin: 15px;
            padding: 15px;
        }
    }
}
table.kit-table1 tr td {
    border-top: 2px solid #e9e9e9;
    padding: 25px 5px;
    vertical-align: middle;
    text-align: left;
    font-family: work_sansregular;
    color: #535353;
    font-weight: normal;
    font-size: 1.14rem;
}

table.kit-table1 tr th {
    padding: 25px 5px;
    vertical-align: middle;
    text-align: left;
    font-size: 1rem;
    font-family: ns_widemedium;
    color: #010101;
    text-transform: uppercase;
    font-weight: normal;
}
table.kit-table1 tr td:first-child,
table.kit-table1 tr th:first-child {
    padding-left: 25px !important;
}
table.kit-table1 tr td:last-child,
table.kit-table1 tr th:last-child {
    padding-right: 25px;
}
table.kit-table1 tfoot tr td,
table.kit-table1 tr th {
    padding-top: 20px;
    padding-bottom: 20px;
}
table.kit-table1 .label1 {
    font-size: 1rem;
    font-family: ns_widemedium;
    color: #010101;
    text-transform: uppercase;
    font-weight: normal;
}
@media(--xsmall){
    table.kit-table1 .label1 {
        padding-right: 20px;
    }
}
/* ------- lexicon table */
table.table-lexicon {
    width: 100%;
    max-width: 100%;
    background: white;
    margin-bottom: 30px;
    -webkit-box-shadow: 0 0 10px 2px rgba(150,150,150,0.1);
    box-shadow: 0 0 10px 2px rgba(150,150,150,0.1);
}
@media(--xsmall){
    table.table-lexicon {
        display: block;
        max-width: 100%;
        margin-bottom: 0;
    }
    table.table-lexicon tbody {
        display: block;
        width: 100%;
    }

    table.table-lexicon tr {
        display: block;
        max-width: 100%;
    }
}
table.table-lexicon tr td {
    border-bottom: 1px solid #e8e8e8;
    padding: 20px 25px;
    vertical-align: top;
    text-align: left;
    font-family: work_sansregular;
    color: #535353;
    font-weight: normal;
    font-size: 1.14rem;
}
@media(--xsmall){
    table.table-lexicon tr td {
        display: block;
        max-width: 100%;
        border-bottom: none !important;
        padding: 10px 20px;
    }
    table.table-lexicon tr td.td-resp,
    table.table-lexicon tr td.td-resp:last-child,
    table.table-lexicon tr:last-child td.td-resp {
        border-bottom: 1px solid #e8e8e8 !important;
        border-top: 1px solid #faa744 !important;
    }
    table.table-lexicon tr:first-child td.td-resp {
        border-top: none !important;
    }
}
table.table-lexicon tr td {
    border-left: 1px solid #e8e8e8;
}
.orange-bord {
    border-bottom: 1px solid #faa744 !important;
}
table.table-lexicon tr:last-child td {
    border-bottom: none !important;
}
table.table-lexicon .t-label1 {
    font-family: work_sansmedium;
    color: #000000;
    font-size: 1.14rem;
}
table.table-lexicon .t-label2 {
    font-family: ns_widemedium;
    color: #010101;
    font-size: 1rem;
    text-transform: uppercase;
}
@media(--xsmall){
    table.table-lexicon .t-label2 {
        display: block;
    }
}
table.table-lexicon a {
    text-decoration: underline;
    color: #00b9f7;
}
table.table-lexicon a:hover {
    text-decoration: none;
    color: #00b9f7;
}
table.table-lexicon p {
    margin: 0;
}