.tabs-nav {
    border-bottom: 2px solid #e3e3e3;
    margin: 0 15px 20px;
}
@media(--medium){
    .tabs-nav {
        display: inline-block;
        margin: 0 15px 15px;
        position: relative;
        text-align: center;
    }
    .blog {
        & .tabs-nav {
            width: 90%;
            max-width: 300px;
        }
    }
    .community {
        & .tabs-nav {
            margin: 0 0px 15px;
        }
    }
    .tabs-nav.resp-padd-search {
        width: 100%;
        margin: 0 0 50px;
    }
    .groups.view {
        & .tabs-nav {
            background: white;
            margin: 0;
            width: 100%;
            padding: 15px;
        }
    }
}
ul.tabs-nav li {
    margin-bottom: -2px;
    margin-right: 20px;
}
@media(--medium){
    ul.tabs-nav li {
        margin-right: 8px;
        margin-left: 8px;
        float: none;
        display: inline-block;
    }
    .blog {
        & ul.tabs-nav {
            border-bottom: none !important;
        }
        & ul.tabs-nav li {
            margin-right: 0 !important;
            margin-left: 0 !important;
            float: none !important;
            display: block !important;
        }
        & .blog-cat-title {
            font-size: 1.4rem !important;
            padding: 5px 0;
        }
    }
    .community {
        & ul.tabs-nav li {
            margin-left: 0;
        }
    }
    .groups {
        & ul.tabs-nav li {
            margin-left: 0;
        }
    }
    ul.tabs-nav li.resp-tab-search {
        float: none !important;
        clear: both;
        width: 100%;
        padding-right: 47px;
        position: absolute;
        left: -8px;
        right: 0;
        bottom: -42px;
    }
    .resp-tab-search {
        & .btn-reset {
            right: 54px !important;
            top: 5px !important;
        }

    }
    .community.find-friends {
        & .resp-tab-search {
            & .btn-reset {
                right: 59px !important;
                top: 7px !important;
                font-size: 3rem !important;
            }
        }
    }
}
ul.tabs-nav li > a {
    background-color: transparent !important;
}
ul.tabs-nav li:last-child {
    margin-right: 0;
}
.tabs-nav.nav-pills>li>a,.user-block .nav-pills>li>a{
    padding: 10px 0;
    line-height: 20px;
    border-radius: 0;
    -webkit-border-radius: 0;
    text-transform: uppercase;
    font-size: 1.07rem;
    font-family: ns_widenormal;
    color: #505050;
}
@media(--medium){
    .tabs-nav.nav-pills>li>a,.user-block .nav-pills>li>a{
        padding: 10px 0 0;
    }
}
.tabs-nav.nav-pills>li.active>a, .tabs-nav.nav-pills>li:hover>a {
    background: transparent !important;
    color: #00b9f7;
    /*font-family: ns_widedemibold;*/
    font-size: 1.07rem;
    border-bottom: 2px solid #00b9f7;
}
@media(--xsmall){
    .tabs-nav.nav-pills>li>a,.user-block .nav-pills>li>a{
        font-size: 0.8rem;
    }
    .tabs-nav.nav-pills>li.active>a,
    .tabs-nav.nav-pills>li:hover>a {
        font-size: 0.8rem;
    }
}
@media(--medium){
    .u-header {
        & .nav-pills {
            text-align: center;
        }
        & .nav-pills>li {
            float: none;
            display: inline-block;
        }
        & .nav-pills>li>a {
            margin-left: 2px;
        }
    }
}
